"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = void 0;
var lodash_1 = require("lodash");
// this state tracks what is actually sent in the query
// so if there's a UI filter but it has no options selected,
// this reducer does not add it
function reducer(state, action) {
    switch (action.type) {
        case 'filter': {
            var filters = filterReducer(state.filters, action);
            return __assign(__assign({}, state), { filters: filters });
        }
        case 'sort': {
            var sorts = sortReducer(state.sorts, action);
            return __assign(__assign({}, state), { sorts: sorts });
        }
        case 'group': {
            var groups = groupReducer(state.groups, action);
            return __assign(__assign({}, state), { groups: groups });
        }
        case 'resetGroups': {
            return __assign(__assign({}, state), { groups: [] });
        }
        case 'reset': {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}
exports.reducer = reducer;
function filterReducer(filters, action) {
    var operation = action.operation, payload = action.payload;
    var filter = payload.filter, selection = payload.selection;
    if ((0, lodash_1.isNil)(filter) || (0, lodash_1.isNil)(filter.uuid)) {
        return filters;
    }
    var nextFilters = new Map(filters);
    switch (operation) {
        case 'addFilter':
            if (!(0, lodash_1.isNil)(selection)) {
                nextFilters.set(filter.uuid, payload);
            }
            else {
                nextFilters.delete(filter.uuid);
            }
            break;
        case 'removeFilter':
            nextFilters.delete(filter.uuid);
            break;
        case 'removeAllFilters':
            nextFilters.clear();
            break;
        case 'addSelections':
        case 'updateSelections':
            if (!(0, lodash_1.isNil)(selection)) {
                nextFilters.set(filter.uuid, payload);
            }
            else {
                nextFilters.delete(filter.uuid);
            }
            break;
        case 'removeAllSelections':
            nextFilters.delete(filter.uuid);
            break;
    }
    return nextFilters;
}
function sortReducer(sorts, action) {
    var payload = action.payload;
    if ((0, lodash_1.isNil)(payload.uuid)) {
        return sorts;
    }
    var nextSorts = new Map(sorts);
    if (!(0, lodash_1.isNil)(payload.direction)) {
        nextSorts.set(payload.uuid, { sort: payload, direction: payload.direction });
    }
    else {
        nextSorts.delete(payload.uuid);
    }
    return nextSorts;
}
function groupReducer(groups, action) {
    var _a;
    var index = action.groupIndex;
    var payload = action.payload;
    var filter = payload.filter, selection = payload.selection;
    if ((0, lodash_1.isNil)(filter === null || filter === void 0 ? void 0 : filter.uuid)) {
        return groups;
    }
    var nextGroups = __spreadArray([], groups, true);
    if (!(0, lodash_1.isNil)(filter) && (0, lodash_1.isNil)(index)) {
        var newGroup = new Map();
        nextGroups = __spreadArray(__spreadArray([], nextGroups, true), [newGroup], false);
        index = nextGroups.length - 1;
    }
    if (!(0, lodash_1.isNil)(index)) {
        if (!(0, lodash_1.isNil)(filter) && !(0, lodash_1.isEmpty)(selection)) {
            nextGroups[index].set(filter.uuid, payload);
        }
        else if ((0, lodash_1.isNil)(filter)) {
            nextGroups.splice(index, 1);
        }
        else {
            (_a = nextGroups[index]) === null || _a === void 0 ? void 0 : _a.delete(filter.uuid);
        }
    }
    return nextGroups;
}
