"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchControls = void 0;
var ControlsProvider_1 = require("@shared/components/filter/state/ControlsProvider");
var use_debounce_1 = require("use-debounce");
var useSearchControls = function () {
    var _a = (0, ControlsProvider_1.useControlsContext)(), search = _a.search, setSearch = _a.setSearch;
    var onChange = (0, use_debounce_1.useDebouncedCallback)(setSearch, 500)[0];
    return {
        search: search,
        setSearch: setSearch,
        onChange: onChange,
    };
};
exports.useSearchControls = useSearchControls;
