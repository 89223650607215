"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGroupControls = void 0;
var uuid = __importStar(require("@shared/util/uuid"));
var react_1 = require("react");
var ControlsProvider_1 = require("@shared/components/filter/state/ControlsProvider");
var useGroupControls = function (defaultOptions) {
    if (defaultOptions === void 0) { defaultOptions = {}; }
    var _a = (0, ControlsProvider_1.useControlsContext)(), availableGroups = _a.availableGroups, selectedGroups = _a.selectedGroups, setSelectedGroups = _a.setSelectedGroups, setGroupOption = _a.setGroupOption, groups = _a.groups, resetGroups = _a.resetGroups;
    var onAddGroup = function (item) {
        var newItem = __assign(__assign(__assign({}, item), { uuid: uuid.v4() }), defaultOptions);
        setSelectedGroups(function (prev) { return __spreadArray(__spreadArray([], prev, true), [[newItem]], false); });
        setGroupOption(newItem);
    };
    var onAddFilter = function (item, index) {
        var newItem = __assign(__assign(__assign({}, item), { uuid: uuid.v4() }), defaultOptions);
        setSelectedGroups(function (prev) {
            var newItems = __spreadArray([], prev, true);
            newItems[index].push(newItem);
            return newItems;
        });
        setGroupOption(newItem, index);
    };
    var onUpdateFilter = function (item, index) {
        setSelectedGroups(function (prev) {
            var newItems = __spreadArray([], prev, true);
            var itemIndex = newItems[index].findIndex(function (i) { return i.uuid === item.uuid; });
            newItems[index][itemIndex] = item;
            return newItems;
        });
        setGroupOption(item, index);
    };
    var onDeleteFilter = (0, react_1.useCallback)(function (item, index) {
        setSelectedGroups(function (prev) {
            if (prev[index].length === 1) {
                return __spreadArray(__spreadArray([], prev.slice(0, index), true), prev.slice(index + 1, prev.length), true);
            }
            return __spreadArray(__spreadArray(__spreadArray([], prev.slice(0, index), true), [
                prev[index].filter(function (i) { return i.uuid !== item.uuid; })
            ], false), prev.slice(index + 1, prev.length), true);
        });
        if (selectedGroups[index].length === 1) {
            setGroupOption(undefined, index);
        }
        else {
            setGroupOption(item, index);
        }
    }, [selectedGroups, setGroupOption, setSelectedGroups]);
    var onDeleteGroup = function (index) {
        setSelectedGroups(function (prev) {
            return __spreadArray(__spreadArray([], prev.slice(0, index), true), prev.slice(index + 1, prev.length), true);
        });
        setGroupOption(undefined, index);
    };
    var onDeleteAllGroups = function () {
        setSelectedGroups([]);
        resetGroups();
    };
    return {
        onAddGroup: onAddGroup,
        onAddFilter: onAddFilter,
        onUpdateFilter: onUpdateFilter,
        onDeleteFilter: onDeleteFilter,
        onDeleteGroup: onDeleteGroup,
        onDeleteAllGroups: onDeleteAllGroups,
        selectedGroups: selectedGroups,
        availableGroups: availableGroups,
        setGroupOption: setGroupOption,
        groups: groups,
    };
};
exports.useGroupControls = useGroupControls;
