"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitLaunchDarkly = exports.useFeatureFlag = void 0;
var launchdarkly_react_client_sdk_1 = require("launchdarkly-react-client-sdk");
var shared_1 = require("./shared");
var react_1 = require("react");
var lodash_1 = require("lodash");
var useFeatureFlag = function (flagName) {
    var _a;
    var flags = (0, launchdarkly_react_client_sdk_1.useFlags)();
    return (_a = flags[flagName]) !== null && _a !== void 0 ? _a : shared_1.FLAGS[flagName].defaultVal;
};
exports.useFeatureFlag = useFeatureFlag;
var useInitLaunchDarkly = function () {
    var ldClient = (0, launchdarkly_react_client_sdk_1.useLDClient)();
    var context = (0, shared_1.useLaunchDarklyContext)();
    (0, react_1.useEffect)(function () {
        if ((0, lodash_1.isNil)(context) || (0, lodash_1.isNil)(ldClient)) {
            return;
        }
        ldClient.identify(context);
    }, [context, ldClient]);
};
exports.useInitLaunchDarkly = useInitLaunchDarkly;
__exportStar(require("./shared"), exports);
