"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useShowRedactedModal = void 0;
var react_1 = require("react");
var usePaywallExperiment_1 = require("@shared/util/featureFlags/launchDarkly/hooks/usePaywallExperiment");
var provider_1 = require("@shared/scenes/billing/upgradeModal/provider");
var i18n_1 = require("@shared/util/i18n");
var useIsCardBeforePaidCutoff_1 = require("@shared/data/billing/hooks/useIsCardBeforePaidCutoff");
function useShowRedactedModal() {
    var reportRedactedClick = (0, usePaywallExperiment_1.useTrackPaywallExperimentClick)('redacted');
    var showUpgradeModal = (0, react_1.useContext)(provider_1.UpgradeModalContext).show;
    return (0, react_1.useCallback)(function () {
        reportRedactedClick();
        showUpgradeModal({
            title: (0, i18n_1.i18n)('card.upgradeModal.title', { count: useIsCardBeforePaidCutoff_1.CARD_VIEW_HISTORY_CUTOFF_DAYS }),
            description: (0, i18n_1.i18n)('card.upgradeModal.description'),
        });
    }, [showUpgradeModal, reportRedactedClick]);
}
exports.useShowRedactedModal = useShowRedactedModal;
