"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFilterControls = void 0;
// @ts-strict-ignore
var uuid = __importStar(require("@shared/util/uuid"));
var ControlsProvider_1 = require("@shared/components/filter/state/ControlsProvider");
var useFilterControls = function (defaultOptions) {
    if (defaultOptions === void 0) { defaultOptions = {}; }
    var _a = (0, ControlsProvider_1.useControlsContext)(), filters = _a.filters, availableFilters = _a.availableFilters, selectedFilters = _a.selectedFilters, setSelectedFilters = _a.setSelectedFilters, setFilterOption = _a.setFilterOption;
    var onAdd = function (item) {
        var newItem = __assign(__assign(__assign({}, item), { uuid: uuid.v4() }), defaultOptions);
        setSelectedFilters(function (prev) { return __spreadArray(__spreadArray([], prev, true), [newItem], false); });
        setFilterOption(newItem, 'addFilter');
        return newItem;
    };
    var onUpdate = function (item) {
        setSelectedFilters(function (prev) {
            var newItems = __spreadArray([], prev, true);
            var index = newItems.findIndex(function (i) { return i.uuid === item.uuid; });
            newItems[index] = item;
            return newItems;
        });
        setFilterOption(item, 'addFilter');
        return item;
    };
    var onDelete = function (item) {
        setSelectedFilters(function (prev) { return __spreadArray([], prev, true).filter(function (i) { return i.uuid !== item.uuid; }); });
        setFilterOption(item, 'removeFilter');
    };
    var onClearAll = function () {
        setSelectedFilters([]);
        setFilterOption(null, 'removeAllFilters');
    };
    var onClearAllOptions = function (item) {
        setFilterOption(item, 'removeAllSelections');
    };
    return {
        onAdd: onAdd,
        onUpdate: onUpdate,
        onDelete: onDelete,
        onClearAll: onClearAll,
        onClearAllOptions: onClearAllOptions,
        selectedFilters: selectedFilters,
        availableFilters: availableFilters,
        setFilterOption: setFilterOption,
        filters: filters,
    };
};
exports.useFilterControls = useFilterControls;
