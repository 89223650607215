"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useReferenceableWorkflows = void 0;
// @ts-strict-ignore
var hooks_1 = require("@shared/scenes/workflows/hooks");
var lodash_1 = require("lodash");
function useReferenceableWorkflows(parentWorkflowTemplateId, workspaceDetails) {
    // TODO don't load all
    var _a = (0, hooks_1.useListWorkflowTemplates)({
        input: {
            relatesToWorkflowTemplateId: parentWorkflowTemplateId,
        },
        loadAll: true,
    }), workflowTemplates = _a.workflowTemplates, loading = _a.loading;
    var referenceableWorkflows = !(0, lodash_1.isEmpty)(workflowTemplates)
        ? workflowTemplates.map(function (template) {
            var _a, _b, _c;
            return {
                workflowTemplateId: template.id,
                imageUrl: (_a = template.channelDefaultConfiguration) === null || _a === void 0 ? void 0 : _a.imageUrl,
                name: (_c = (_b = workspaceDetails.get(template.id)) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : template.name,
                components: template.components,
            };
        })
        : [];
    return { loading: loading, workflows: referenceableWorkflows };
}
exports.useReferenceableWorkflows = useReferenceableWorkflows;
