"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubformField = void 0;
var lodash_1 = require("lodash");
var SubformField = /** @class */ (function () {
    function SubformField(jsonBlob, workflowTemplateRefs) {
        this.fieldType = 'SubformField';
        this.jsonBlob = jsonBlob;
        this.workflowTemplate = workflowTemplateRefs === null || workflowTemplateRefs === void 0 ? void 0 : workflowTemplateRefs.find(function (w) { return w.id === (jsonBlob === null || jsonBlob === void 0 ? void 0 : jsonBlob.workflowTemplateId); });
    }
    SubformField.prototype.validate = function () {
        return null;
    };
    SubformField.prototype.isEqual = function (field) {
        return field instanceof SubformField && this.jsonBlob === field.jsonBlob;
    };
    SubformField.prototype.toFieldJson = function () {
        return this.jsonBlob;
    };
    Object.defineProperty(SubformField.prototype, "isEmpty", {
        get: function () {
            var _a;
            return (0, lodash_1.isNil)((_a = this.jsonBlob) === null || _a === void 0 ? void 0 : _a.workflowTemplateId);
        },
        enumerable: false,
        configurable: true
    });
    return SubformField;
}());
exports.SubformField = SubformField;
