"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateEntityWidget = void 0;
// @ts-strict-ignore
var client_1 = require("@apollo/client");
var api_1 = require("@shared/api");
var lodash_1 = require("lodash");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var uuid_1 = require("@shared/util/uuid");
var hooks_1 = require("@shared/scenes/workflows/hooks");
function useCreateEntityWidget() {
    var _a, _b, _c, _d, _e, _f;
    var businessId = (0, react_redux_1.useSelector)(function (store) { return store.appState.currentBusinessId; });
    var dashboards = (0, client_1.useQuery)(api_1.Queries.dashboard.listDashboards, {
        fetchPolicy: 'network-only',
    });
    var workflowTemplateIds = (0, lodash_1.uniq)((_e = (_d = (_c = (_b = (_a = dashboards.data) === null || _a === void 0 ? void 0 : _a.listDashboards) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.entityWidgets) === null || _d === void 0 ? void 0 : _d.map(function (ew) { return ew.workflowTemplateId; })) !== null && _e !== void 0 ? _e : []);
    var _g = (0, hooks_1.useListWorkflowTemplates)({
        input: { ids: workflowTemplateIds },
        loadAll: true,
        skip: (0, lodash_1.isEmpty)(workflowTemplateIds),
    }), workflowTemplates = _g.workflowTemplates, loadingWorkflowTemplates = _g.loading;
    var updateDashboard = (0, client_1.useMutation)(api_1.Mutations.dashboard.updateDashboard)[0];
    var createDashboard = (0, client_1.useMutation)(api_1.Mutations.dashboard.createDashboard)[0];
    var createEntityWidget = (0, react_1.useCallback)(function (viewTemplateId, workflowTemplateId, name, channelId) {
        var _a, _b;
        if (loadingWorkflowTemplates) {
            createEntityWidget(viewTemplateId, workflowTemplateId, name, channelId);
        }
        if (!(0, lodash_1.isEmpty)((_a = dashboards.data) === null || _a === void 0 ? void 0 : _a.listDashboards)) {
            var dashboard = (0, lodash_1.first)((_b = dashboards.data) === null || _b === void 0 ? void 0 : _b.listDashboards);
            var entityWidgets = __spreadArray([], dashboard.entityWidgets.filter(function (ew) { return !(0, lodash_1.isNil)(workflowTemplates.find(function (wft) { return wft.id === ew.workflowTemplateId; })); }), true);
            entityWidgets.push({
                id: (0, uuid_1.v4)(),
                workflowTemplateId: workflowTemplateId,
                viewTemplateId: viewTemplateId,
                name: name,
                channelId: channelId,
            });
            return updateDashboard({
                variables: {
                    input: {
                        businessId: dashboard.businessId,
                        id: dashboard.id,
                        entityWidgets: entityWidgets,
                    },
                },
                refetchQueries: [{ query: api_1.Queries.dashboard.listDashboards }],
            });
        }
        else {
            return createDashboard({
                variables: {
                    input: {
                        businessId: businessId,
                        entityWidgets: [
                            {
                                workflowTemplateId: workflowTemplateId,
                                viewTemplateId: viewTemplateId,
                                name: name,
                                channelId: channelId,
                            },
                        ],
                    },
                },
                refetchQueries: [{ query: api_1.Queries.dashboard.listDashboards }],
            });
        }
    }, [
        businessId,
        createDashboard,
        (_f = dashboards.data) === null || _f === void 0 ? void 0 : _f.listDashboards,
        updateDashboard,
        loadingWorkflowTemplates,
        workflowTemplates,
    ]);
    return {
        loading: dashboards.loading,
        createEntityWidget: createEntityWidget,
    };
}
exports.useCreateEntityWidget = useCreateEntityWidget;
