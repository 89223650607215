"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBNavStackProvider = exports.useNavStack = exports.NavStackContext = void 0;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
exports.NavStackContext = (0, react_1.createContext)(null);
function useNavStack(initialRoutes) {
    if (initialRoutes === void 0) { initialRoutes = []; }
    var _a = (0, react_1.useState)(initialRoutes), stack = _a[0], setStack = _a[1];
    var pop = (0, react_1.useCallback)(function () {
        if (stack.length === 0) {
            return undefined;
        }
        var stackCopy = __spreadArray([], stack, true);
        var route = stackCopy.pop();
        setStack(stackCopy);
        return route;
    }, [stack]);
    var push = (0, react_1.useCallback)(function (route) {
        var stackCopy = __spreadArray([], stack, true);
        stackCopy.push(route);
        setStack(stackCopy);
    }, [stack]);
    var replace = (0, react_1.useCallback)(function (route) {
        if (stack.length === 0) {
            return;
        }
        var stackCopy = __spreadArray([], stack, true);
        stackCopy[stackCopy.length - 1] = route;
        setStack(stackCopy);
    }, [stack]);
    var reset = (0, react_1.useCallback)(function (routes) {
        if (routes === void 0) { routes = []; }
        setStack(__spreadArray([], routes, true));
    }, []);
    return (0, react_1.useMemo)(function () { return ({ pop: pop, push: push, replace: replace, reset: reset, currentRoute: stack[stack.length - 1] }); }, [pop, push, replace, reset, stack]);
}
exports.useNavStack = useNavStack;
var MBNavStackProvider = function (_a) {
    var initialRoutes = _a.initialRoutes, children = _a.children;
    var ctx = useNavStack(initialRoutes);
    return react_1.default.createElement(exports.NavStackContext.Provider, { value: ctx }, children);
};
exports.MBNavStackProvider = MBNavStackProvider;
