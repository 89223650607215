"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubforms = void 0;
var hooks_1 = require("@shared/scenes/workflows/hooks");
var lodash_1 = require("lodash");
var hooks_2 = require("../hooks");
function useSubforms(_a) {
    var _b, _c, _d;
    var workflowTemplateId = _a.workflowTemplateId, name = _a.name, _e = _a.loadAll, loadAll = _e === void 0 ? false : _e;
    var _f = (0, hooks_1.useWorkflowTemplate)(workflowTemplateId), workflowTemplate = _f.workflowTemplate, workflowTemplateLoading = _f.loading, workflowTemplateError = _f.error;
    var ids = (0, lodash_1.flatten)((_c = (_b = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components) === null || _b === void 0 ? void 0 : _b.filter(hooks_2.isSubformComponent).map(function (c) { return c.workflowTemplateIds; })) !== null && _c !== void 0 ? _c : []);
    var _g = (0, hooks_1.useListWorkflowTemplates)({
        skip: (0, lodash_1.isEmpty)(ids),
        input: { ids: ids, name: (0, lodash_1.isEmpty)(name) ? undefined : name },
        loadAll: loadAll,
    }), workflowTemplates = _g.workflowTemplates, workflowTemplatesLoading = _g.loading, workflowTemplatesError = _g.error, hasMore = _g.hasMore, loadMore = _g.loadMore;
    var componentAndWorkflowTemplates = !workflowTemplatesLoading
        ? (_d = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components) === null || _d === void 0 ? void 0 : _d.filter(hooks_2.isSubformComponent).map(function (c) { return ({
            componentId: c.id,
            workflowTemplates: !(0, lodash_1.isEmpty)(workflowTemplates)
                ? c.workflowTemplateIds.map(function (id) { return workflowTemplates === null || workflowTemplates === void 0 ? void 0 : workflowTemplates.find(function (wft) { return wft.id === id; }); })
                : [],
        }); })
        : [];
    var error = workflowTemplateError || workflowTemplatesError;
    var loading = workflowTemplateLoading || workflowTemplatesLoading;
    var subforms = [];
    componentAndWorkflowTemplates === null || componentAndWorkflowTemplates === void 0 ? void 0 : componentAndWorkflowTemplates.forEach(function (item) {
        var _a;
        (_a = item.workflowTemplates) === null || _a === void 0 ? void 0 : _a.forEach(function (wft) {
            if ((0, lodash_1.isNil)(wft)) {
                return;
            }
            subforms.push({
                workflowTemplate: wft,
                componentId: item.componentId,
                createdAt: wft === null || wft === void 0 ? void 0 : wft.createdAt,
            });
        });
    });
    return { subforms: subforms, error: error, loading: loading, hasMore: hasMore, loadMore: loadMore };
}
exports.useSubforms = useSubforms;
