"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAgendaView = void 0;
var useCollectionViewData_1 = require("@shared/scenes/cards/collectionView/hooks/useCollectionViewData");
var react_1 = require("react");
var useUpdateList_1 = require("@shared/data/cards/hooks/useUpdateList");
function useAgendaView(props) {
    var pageSize = useUpdateList_1.CARDS_PER_PAGE;
    var agendaView = (0, useCollectionViewData_1.useCollectionViewData)(__assign(__assign({}, props), { pageSize: pageSize }));
    var entities = agendaView.entities, hasMoreCards = agendaView.hasMoreCards, fetchMore = agendaView.fetchMore;
    (0, react_1.useEffect)(function () {
        if (hasMoreCards) {
            fetchMore();
        }
        // including entities in the dependency array so we re-check hasMoreCards when entities change
    }, [entities, hasMoreCards, fetchMore]);
    return agendaView;
}
exports.useAgendaView = useAgendaView;
