"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeaderIconButton = exports.MBChannelWebLayoutHeader = void 0;
// @ts-strict-ignore
var components_1 = require("@shared/components");
var index_1 = require("@shared/scenes/calling/provider/index");
var desktopNavControls_1 = require("@shared/scenes/cards/collection/containers/desktopNavControls");
var hooks_1 = require("@shared/scenes/cards/hooks");
var hooks_2 = require("@shared/scenes/channel/options/hooks");
var components_2 = require("@shared/scenes/developerMenu/components");
var hooks_3 = require("@shared/scenes/developerMenu/hooks");
var styles_1 = require("@shared/styles");
var hooks_4 = require("@shared/util/featureFlags/hooks");
var i18n_1 = require("@shared/util/i18n");
var hooks_5 = require("@shared/util/navigation/hooks");
var useNavigator_1 = require("@shared/util/navigation/useNavigator");
var hooks_6 = require("@shared/util/permissions/hooks");
var react_1 = __importStar(require("react"));
var ScreenSize_1 = require("../ScreenSize");
var react_native_1 = require("react-native");
// TODO(fant): the typings in this module are incorrect
var emojiRegex = require('emoji-regex');
var MBChannelWebLayoutHeader = function (_a) {
    var _b;
    var channel = _a.channel, onPress = _a.onPress;
    var params = (0, react_1.useContext)(hooks_5.ReactRouterContext).params;
    var _c = (0, react_1.useContext)(index_1.CallContext), joinCall = _c.joinCall, callState = _c.state;
    var cardDefinition = (0, hooks_1.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).cardDefinition;
    var reports = (0, hooks_2.useChannelReports)({
        cardDefinitionId: cardDefinition === null || cardDefinition === void 0 ? void 0 : cardDefinition.id,
        workflowTemplateId: channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId,
    });
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var navigateToAddUsers = (0, useNavigator_1.useNavigateTo)('ChannelAddPeople', { channelSlug: channel === null || channel === void 0 ? void 0 : channel.slug });
    var navigateToUserList = (0, useNavigator_1.useNavigateTo)('ChannelUserList', { channelSlug: channel === null || channel === void 0 ? void 0 : channel.slug });
    var screenSize = (0, ScreenSize_1.useScreenSize)();
    var addUserPermission = (0, hooks_6.usePermission)({
        action: 'create',
        subject: 'ChannelMembership',
        channelId: channel === null || channel === void 0 ? void 0 : channel.id,
    });
    var showDeveloperTools = (0, hooks_4.useFeatureFlag)(hooks_3.DEV_TOOLS_FEATURE_FLAG).enabled;
    var _d = (0, react_1.useState)(false), isHoveringMembers = _d[0], setIsHoveringMembers = _d[1];
    return (react_1.default.createElement(components_1.MBView, { row: true },
        react_1.default.createElement(components_1.MBTouchableOpacity, { style: { flex: 1 }, onPress: onPress },
            react_1.default.createElement(components_1.MBView, { row: true, paddingLeft: 4, paddingRight: 2, style: { height: (0, components_1.grid)(11) }, centerVertically: true },
                react_1.default.createElement(components_1.MBChannelImage, { channel: channel }),
                react_1.default.createElement(components_1.MBView, { flex: true, paddingLeft: 1 },
                    react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, paddingRight: 2, paddingTop: 1 },
                        react_1.default.createElement(components_1.MBView, { paddingLeft: 1 }),
                        react_1.default.createElement(components_1.MBTextH3, { color: colors.foregroundActive, numberOfLines: 1, testID: "main-header-channel-name" }, (channel === null || channel === void 0 ? void 0 : channel.name.replace(emojiRegex(), '').trim()) || '-'),
                        react_1.default.createElement(components_1.MBView, { paddingLeft: 1 }),
                        !!channel && (react_1.default.createElement(components_1.MBIconV2, { color: colors.foregroundActive, name: "arrow-point-down-heavy", size: (0, components_1.grid)(2) }))),
                    react_1.default.createElement(components_1.MBView, { row: true, centerVertically: true, style: { flexWrap: 'wrap', paddingBottom: (0, components_1.grid)(1) } },
                        react_1.default.createElement(components_1.MBTouchableOpacity, { onPress: navigateToUserList },
                            react_1.default.createElement(components_1.MBHover, { onHoverStart: function () { return setIsHoveringMembers(true); }, onHoverEnd: function () { return setIsHoveringMembers(false); } },
                                react_1.default.createElement("div", { title: (0, i18n_1.i18n)('channel.header.tooltips.viewMembers'), style: {
                                        borderRadius: (0, components_1.grid)(2),
                                        paddingLeft: (0, components_1.grid)(1),
                                        paddingRight: (0, components_1.grid)(1),
                                        height: (0, components_1.grid)(3),
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: isHoveringMembers
                                            ? colors.backgroundInactive
                                            : colors.background,
                                    } },
                                    react_1.default.createElement(components_1.MBTextBody2, null, (0, i18n_1.i18n)('channel.header.members', { count: ((_b = channel === null || channel === void 0 ? void 0 : channel.users) === null || _b === void 0 ? void 0 : _b.length) || 0 }))))),
                        ['medium', 'large'].includes(screenSize) && (react_1.default.createElement(react_native_1.View, { style: { flexDirection: 'row', flexWrap: 'nowrap', paddingLeft: (0, components_1.grid)(1) } },
                            addUserPermission.enabled && (react_1.default.createElement(exports.HeaderIconButton, { tooltip: (0, i18n_1.i18n)('channel.header.tooltips.invite'), onPress: navigateToAddUsers, source: require('@shared/assets/icons/v2/avatar-invite.png'), testID: "header-action-button-invite" })),
                            (reports === null || reports === void 0 ? void 0 : reports.length) > 0 && (react_1.default.createElement(exports.HeaderIconButton, { tooltip: (0, i18n_1.i18n)('channel.header.tooltips.reports'), onPress: onPress, source: require('@shared/assets/icons/v2/graph-analytics.png'), testID: "header-action-button-reports" })),
                            callState === 'STATE_IDLE' && (react_1.default.createElement(exports.HeaderIconButton, { tooltip: (0, i18n_1.i18n)('channel.header.tooltips.startCall'), onPress: function () { return joinCall(channel.slug); }, source: require('@shared/assets/icons/v2/phone.png'), testID: "header-action-button-phone" })),
                            react_1.default.createElement(exports.HeaderIconButton, { tooltip: (0, i18n_1.i18n)('channel.header.tooltips.details'), onPress: onPress, source: require('@shared/assets/icons/v2/info-outline.png'), testID: "header-action-button-details" }),
                            showDeveloperTools && react_1.default.createElement(components_2.MBDeveloperMenu, { channelSlug: channel === null || channel === void 0 ? void 0 : channel.slug }))))))),
        react_1.default.createElement(desktopNavControls_1.MBCardCollectionDesktopNavControls, { channelSlug: channel === null || channel === void 0 ? void 0 : channel.slug, context: params.collectionContext, templateSlug: params.collectionTemplateSlug })));
};
exports.MBChannelWebLayoutHeader = MBChannelWebLayoutHeader;
exports.MBChannelWebLayoutHeader.displayName = 'MBChannelWebLayoutHeader';
var HeaderIconButton = function (_a) {
    var tooltip = _a.tooltip, source = _a.source, onPress = _a.onPress, testID = _a.testID;
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _b = (0, react_1.useState)(false), isHovered = _b[0], setIsHovered = _b[1];
    return (react_1.default.createElement("div", { style: { paddingRight: (0, components_1.grid)(2) }, title: tooltip },
        react_1.default.createElement(components_1.MBHover, { onHoverStart: function () { return setIsHovered(true); }, onHoverEnd: function () { return setIsHovered(false); } },
            react_1.default.createElement(components_1.MBIconButton, { source: source, color: colors.foregroundActive, backgroundColor: isHovered ? colors.backgroundInactive : colors.background, size: "small", onPress: onPress, testID: testID }))));
};
exports.HeaderIconButton = HeaderIconButton;
exports.HeaderIconButton.displayName = 'HeaderIconButton';
