"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBCardCollectionDesktopNavControls = void 0;
var desktopNavControls_1 = require("@shared/scenes/cards/collection/components/desktopNavControls");
var hooks_1 = require("@shared/util/hooks");
var react_1 = __importStar(require("react"));
var hooks_2 = require("../hooks");
var hooks_3 = require("@shared/scenes/cards/hooks");
var lodash_1 = require("lodash");
var MBCardCollectionDesktopNavControls = function (_a) {
    var channelSlug = _a.channelSlug, templateSlug = _a.templateSlug, context = _a.context;
    var channel = (0, hooks_1.useGetChannel)({ channelSlug: channelSlug });
    var _b = (0, hooks_2.useCollectionViewTemplate)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, channelSlug, templateSlug), viewTemplate = _b.viewTemplate, loading = _b.loading;
    var selectedTemplate = { slug: viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.id, title: viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.name };
    var navigateToTemplate = (0, hooks_2.useNavigateToTemplate)(channelSlug);
    if (!(0, lodash_1.isNil)(channel) && (0, lodash_1.isNil)(channel.workflowTemplateId)) {
        return (react_1.default.createElement(MBLCCardCollectionDesktopNavControls, { channelSlug: channelSlug, context: context, templateSlug: templateSlug }));
    }
    return (react_1.default.createElement(desktopNavControls_1.MBCardCollectionDesktopNavControls, { channelSlug: channelSlug, selectedTemplate: selectedTemplate, onSelectTemplate: navigateToTemplate, viewLoading: loading }));
};
exports.MBCardCollectionDesktopNavControls = MBCardCollectionDesktopNavControls;
exports.MBCardCollectionDesktopNavControls.displayName = 'MBCardCollectionDesktopNavControlsContainer';
var MBLCCardCollectionDesktopNavControls = function (_a) {
    var _b, _c;
    var channelSlug = _a.channelSlug, context = _a.context, templateSlug = _a.templateSlug;
    var template = (0, hooks_2.useCollectionCardTemplate)({
        context: context,
        templateSlug: templateSlug,
        channelSlug: channelSlug,
    }).template;
    var channel = (0, hooks_1.useGetChannel)({ channelSlug: channelSlug });
    var _d = (0, hooks_3.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId), cardDefinition = _d.cardDefinition, loading = _d.loading;
    var allTemplates = (0, hooks_3.useCardTemplates)(cardDefinition);
    var selectableTemplates = (0, react_1.useMemo)(function () {
        return (allTemplates === null || allTemplates === void 0 ? void 0 : allTemplates.filter(function (t) {
            return hooks_2.collectionContexts.includes(t.context);
        })) || [];
    }, [allTemplates]);
    var selectedTemplate = { slug: (_b = template === null || template === void 0 ? void 0 : template.slug) !== null && _b !== void 0 ? _b : '', title: (_c = template === null || template === void 0 ? void 0 : template.title) !== null && _c !== void 0 ? _c : '' };
    var navigateToTemplate = (0, hooks_2.useNavigateToTemplate)(channelSlug);
    return (react_1.default.createElement(desktopNavControls_1.MBCardCollectionDesktopNavControls, { channelSlug: channelSlug, selectedTemplate: selectedTemplate, templates: selectableTemplates, onSelectTemplate: navigateToTemplate, viewLoading: loading }));
};
